@import 'src/styles/variables/variables';

@include useTheme(2025);

.Pricing {
  @include primaryFont();
  letter-spacing: -0.8px;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
}

.Display {
  @include secondaryFont;
  letter-spacing: 0.8px;
  font-size: 60px;
  line-height: 60px;

  @include min-width(tablet) {
    font-size: 90px;
    line-height: 94px;
  }

  &.Core2 {
    color: $core2;
  }
}

.Title, // @deprecated: Use 'Header'
.Header {
  @include secondaryFont;
  font-size: 38px;
  line-height: 42px;
  letter-spacing: -0.4px;
  text-align: center;
  margin-bottom: 20px;

  @include min-width(tablet) {
    font-size: 58px;
    line-height: 62px;
  }

  &.Core1 {
    color: $core1;
  }

  &.Core2 {
    color: $core2;
  }

  &.Core4 {
    color: $core4;
  }

}

.Title1 {
  @include secondaryFont;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 15px;
  letter-spacing: -0.4px;

  &.Contrast {
    color: $core2;
  }

  @include min-width(tablet) {
    font-size: 48px;
    line-height: 58px;
  }
}

.Title2 {
  @include secondaryFont;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.4px;

  &.Contrast {
    color: $core2;
  }

  &.Core4 {
    color: $core4;
  }

  @include min-width(tablet) {
    font-size: 34px;
    line-height: 38px;
  }
}

.Title3 {
  @include secondaryFont;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0px;

  &.Contrast {
    color: $core2;
  }

  &.Core4 {
    color: $core4;
  }

  @include min-width(tablet) {
    font-size: 28px;
    line-height: 36px;
  }
}

.Title4 {
  @include secondaryFont;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.4px;

  &.Contrast {
    color: $core2;
  }

  &.Core4 {
    color: $core4;
  }

  @include min-width(tablet) {
    font-size: 25px;
    line-height: 28px;
  }
}

.Overline {
  @include primaryFont(500);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;

  &.Contrast {
    color: $core2;
  }

  @include min-width(tablet) {
    font-size: 16px;
    line-height: 20px;
  }
}

/* Base fonts/colors for the page */
.Body,
.Modal {
  @include primaryFont;
  font-size: 16px;
  line-height: 24px;
  color: $core1;
  letter-spacing: -0.4px;

  &.Contrast {
    color: $core2;
  }

  button {
    color: $core1;
    outline: none;
    border: none;
    background-color: transparent;
  }

  a {
    text-decoration: none;
    &.Core2 {
      color: $core2;
    }
  }

  p {
    &.Core4 {
      color: $core4;
    }
  }

  &.NormalLetterSpacing {
    letter-spacing: normal;
  }

}

.Navigation {
  @include tertiaryFont();
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.Caption {
  @include primaryFont;
  font-size: 12px;
  line-height: 18px;
}

.BorderBottom {
  border-bottom: $core3 1px solid;
}

.BorderTop {
  border-top: $core3 1px solid;
}

/* Default Buttons */
.Container {
  button {
    outline: none;
    border: none;
    background-color: transparent;
  }
}

.TabLabel {
  @include tertiaryFont;
  font-size: 18px;
  line-height: 24px;
  color: $core1;
  opacity: 0.4;

  &.Active {
    opacity: 1;
  }
}

button.Button {
  outline: none;
  border: none;
  background-color: transparent;
}

.Button {
  @include primaryFont;
  font-size: 16px;
  line-height: 20px;
  height: 48px;
  min-width: 147px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  border-radius: 44px;
  letter-spacing: 1px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;

  background: $core4;
  color: $core2;

  &.isDisabled {
    pointer-events: none;
  }

  &.Medium {
    height: 44px;
    min-height: 44px;
    font-size: 14px;
    line-height: 24px;
    min-width: 151px;
    @include tertiaryFont(500)
  }

  @include min-width(tablet) {
    // Default to the Large size
    height: 48px;
    font-size: 16px;
    min-width: 163px;

    &.Large {
      // redundant, but might be useful to call out explicitly
      height: 60px;
      font-size: 16px;
      min-width: 163px;
    }

    &.Medium {
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      min-width: 151px;
    }

    &.Small {
      padding: 0 20px;
      height: 40px;
      font-size: 14px;
      min-width: 151px;
    }
  }

  &.Primary {
    color: $core2;
    background: $core8;
    font-weight: 600;

    &.Disabled {
      color: rgba($core2, 0.4);
      background-color: rgba($core8, 0.4);
    }

    &.AlternateHover {
      &:hover {
        background: $core2;
        border: 1px solid $core8;
      }
    }
  }

  &.Secondary {
    color: $core8;
    background: transparent;
    font-weight: 600;

    border: 1px solid $core8;

    &.Disabled {
      color: rgba($core8, 0.4);
      border: 1px solid rgba($core8, 0.4);
    }

    &.Conflicting {
      color: $core1;
      background: transparent;
      border-color: $core2;
    }

    &:hover {
      border: none;
    }
  }

  &.Conflicting {
    color: $core1;
    background: $core2;
    font-weight: 700;

    &.Disabled {
      color: rgba($core1, 0.4);
    }
  }

  &.Transparent {
    color: $core1;
    background: transparent;
    border: 1px solid $core1;
    box-sizing: border-box;

    &.Disabled {
      color: rgba($core1, 0.4);
      border: 1px solid rgba($core1, 0.4);
    }

    &.Conflicting {
      background: transparent;
      border: 1px solid $core2;
      color: $core2;

      &.Disabled {
        color: rgba($core2, 0.4);
        border: 1px solid rgba($core2, 0.4);
      }
    }

    &:hover {
      border: 1px solid $core1;
    }
  }

  /* stylelint-disable */
  &.Conflicting {
    color: $core1;
    background: $core2;

    &.Disabled {
      color: rgba($core1, 0.4);
    }

    &:hover {
      background: $macadamia;
      color: $almond;
    }
  }
  /* stylelint-enable */
  &:active {
    text-decoration: none;
    background: $core4;
    color: $core2;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
      background: $almond;
      color: $macadamia;
    }
  }

  &.Disabled {
    pointer-events: none;
  }
}

.CarouselButton {
  @include primaryFont;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
  color: $core1;

  &.Contrast {
    color: $core2;
  }
}

.Carousel {
  .Bullet {
    height: 12px;
    width: 12px;
    background: $tint1;
    display: inline-block;
    border-radius: 50%;
  }

  .BulletActive {
    background: $core8;
  }
}

.Section {
  &.Core2 {
    background: $core2;
    color: $core1;
  }

  &.Core4 {
    background: $core4;
    color: $core2;

    .Button.Primary {
      background: $core2;
    }

    svg {
      path {
        fill: $core2;
        stroke: $core2;
      }
    }

    .Header,
    .Title1,
    .Title2 {
      color: $core2;
    }
  }

  &.Core5 {
    background: $core5;
    color: $core1;

    .BorderBottom {
      border-bottom: rgba($core1, 0.2) 1px solid;
    }

    .BorderTop {
      border-top: rgba($core1, 0.2) 1px solid;
    }

    .Button.Primary {
      background: $core2;
    }
  }

  &.Core6 {
    background: $core6;
    color: $core1;
  }

  &.Core8 {
    background: $core8;
    color: $core1;

    .Button.Primary {
      background: $core2;
    }
  }

  &.Core7 {
    background: $core7;
    color: $core2;

    .Button.Primary {
      background: $core2;
    }

    .Header,
    .Title1,
    .Title2 {
      color: $core2;
    }

    svg {
      path {
        fill: $core2;
        stroke: $core2;
      }
    }
  }

  &.Tint1 {
    background: $tint1;
    color: $core1;
  }

  &.Tint2 {
    background: $tint2;
    color: $core1;
  }
}

// Image overlay to darken an image/component
.Overlay {
  background-color: $core1;
  opacity: 0.15;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
