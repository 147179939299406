@import 'src/components/theme/default/colors.module.scss';
@import 'src/components/theme/2025/colors.module.scss';

$themeColors: (
  default: $defaultThemeColors,
  2025: $twentyFiveThemeColors,
);

/* Extra Colors */
$cinnamon: #9F4E30;
$almond: #906136;
$macadamia: #EFE3D5;
$espresso: #CCAA8F;
$blueberry: #5162A4;
$caramel: #D3A88B;